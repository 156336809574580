import links from "./links";
import permissions from './permissions';

const menu = [
    {
        name: "Mi Panel",
        path: links.home,
        icon: "dashboard_customize",
        permission: permissions.no_need
    },
    {
        name: "Etapas",
        path: links.step.list,
        icon: "align_horizontal_center",
        permission: permissions.step_list
    },
    {
        name: "Proyectos",
        path: links.project.list,
        icon: "handyman",
        permission: permissions.project_list
    },
    {
        name: "Visor",
        path: 'https://visor.gbsarchitectural.com/',
        icon: "map",
        permission: permissions.no_need
    },
    {
        name: "Marcas",
        path: links.brand.list,
        icon: "category",
        permission: permissions.brand_list
    },
    {
        name: "Productos",
        path: links.product.list,
        icon: "inventory",
        permission: permissions.product_list
    },
    {
        name: "Usuarios",
        path: links.user.list,
        icon: "account_box",
        permission: permissions.user_list
    },
    {
        name: "Roles",
        path: links.userRole.list,
        icon: "badge",
        permission: permissions.rol_list
    },
];

export default menu;