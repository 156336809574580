const permissions = {
    rol_list: 'Listado rol',
    rol_create: "Crear rol",
    rol_update: "Modificar rol",
    rol_delete: "Eliminar rol",
    product_list: "Listado producto",
    product_create: "Crear producto",
    product_update: "Modificar producto",
    product_delete: "Eliminar producto",
    brand_list: "Listado marca",
    brand_create: "Crear marca",
    brand_update: "Modificar marca",
    brand_delete: "Eliminar marca",
    user_list: "Listado usuario",
    user_create: "Crear usuario",
    user_update: "Modificar usuario",
    user_delete: "Eliminar usuario",
    step_list: "Listado etapa",
    step_create: "Crear etapa",
    step_update: "Modificar etapa",
    step_delete: "Eliminar etapa",
    project_list: "Listado proyecto",
    project_create: "Crear proyecto",
    project_update: "Modificar proyecto",
    project_delete: "Eliminar proyecto",
    no_need: "No_need",
}
export default permissions;